import { createElement } from 'react'
import language from '../assets/language.png'
import { SOCIALS } from './social'
import { Lang, LANGUAGES, useGlobalContext } from './context'
import logo from '../assets/logo.png'

const { REACT_APP_APP_TITLE } = process.env

export default function Header() {
  const { lang, setLang } = useGlobalContext()
  return (
    <header className="py-2 flex items-center justify-between">
      <div className="flex items-center space-x-2 sm:space-x-4">
        <h1>
          <img src={logo} alt="" className="h-4 sm:h-10" />
          <span className="sr-only">{REACT_APP_APP_TITLE}</span>
        </h1>

        <nav>
          <ul className="flex items-center space-x-2 sm:space-x-4">
            {Object.entries(SOCIALS).map(([name, { icon, url }]) => (
              <li key={name}>
                <a href={url} target="_blank" rel="noreferrer">
                  {createElement(icon, { className: 'h-4 sm:h-8 text-white' })}
                  <span className="sr-only">{name}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>

      <div className="relative group">
        <div className="inline-flex items-center space-x-1">
          <svg
            className="w-4 h-4 sm:w-10 sm:h-10 text-white"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="currentColor"
              d="M512 64C265.6 64 64 265.6 64 512s201.6 448 448 448 448-201.6 448-448S758.4 64 512 64zM131.2 544h128c3.2 57.6 9.6 112 22.4 160H179.2c-25.6-48-44.8-102.4-48-160zM544 256V134.4c41.6 16 80 57.6 108.8 121.6H544zm134.4 64c12.8 48 22.4 102.4 25.6 160H544V320h134.4zM480 134.4V256H371.2C400 192 438.4 150.4 480 134.4zm0 185.6v160H320c3.2-57.6 12.8-112 25.6-160H480zM256 480H128c6.4-57.6 22.4-112 51.2-160h99.2c-12.8 48-19.2 102.4-22.4 160zm64 64h160v160H345.6c-12.8-48-22.4-102.4-25.6-160zm160 224v121.6c-41.6-16-80-60.8-108.8-121.6H480zm64 121.6V768h108.8C624 832 585.6 873.6 544 889.6zm0-185.6V544h160c-3.2 57.6-12.8 112-25.6 160H544zm224-160h128c-6.4 57.6-22.4 112-51.2 160h-99.2c12.8-48 19.2-102.4 22.4-160zm0-64c-3.2-57.6-9.6-112-22.4-160h99.2c28.8 48 44.8 102.4 51.2 160H768zm28.8-224h-73.6c-12.8-35.2-28.8-64-48-89.6 48 22.4 89.6 51.2 121.6 89.6zm-448-89.6c-19.2 25.6-35.2 54.4-48 89.6h-73.6c32-38.4 73.6-67.2 121.6-89.6zM227.2 768h73.6c12.8 35.2 28.8 64 48 89.6-48-22.4-89.6-51.2-121.6-89.6zm448 89.6c19.2-25.6 35.2-57.6 48-89.6h73.6c-32 38.4-73.6 67.2-121.6 89.6z"
            />
          </svg>
          <img src={language} alt="" className="hidden md:block h-7" />
          <span className="sr-only">Language</span>
        </div>

        <div className="group-hover:flex hidden absolute right-0 top-full flex-col border sm:border-2 border-white rounded-lg sm:rounded-3xl divide-y sm:divide-y-2 divide-white text-sm sm:text-2xl">
          {Object.entries(LANGUAGES).map(([key, label]) => (
            <button
              key={key}
              onClick={() => {
                setLang(key)
                localStorage.setItem('lang', key)
              }}
              className="w-full flex items-center justify-center text-white px-4 sm:px-6 py-1.5 whitespace-nowrap"
            >
              <span className={lang === key ? 'bg-red-200' : undefined}>{label}</span>
            </button>
          ))}
        </div>
      </div>
    </header>
  )
}
