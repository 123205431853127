import zh from '../assets/text/mint.zh.png'
import en from '../assets/text/mint.en.png'
import { useGlobalContext } from "./context"

export default function MintButton() {
  const { lang } = useGlobalContext()
  
  function scrollToCard() {
    const card = document.getElementById('mint-card')
    window.scrollTo(0, card.offsetTop - 16)
  }
  
  return (
    <div className="flex items-center justify-center">
      <button
        onClick={scrollToCard}
        className="flex items-center rounded-full ring-4 ring-[#0FF] ring-opacity-50 shadow-lg px-4 py-2 md:px-8 md:py-4 bg-white bg-opacity-30"
      >
        <img src={lang === 'zh' ? zh : en} alt="" className="h-4 md:h-8 w-auto" />
        <span className="sr-only">{lang === 'zh' ? '铸造一个小明' : 'MINT a XiaoMing'}</span>
      </button>
    </div>
  )
}