import footer from '../assets/guy-green.png'
import { useGlobalContext } from './context'

const { REACT_APP_CONTRACT_ADDRESS } = process.env

export default function Footer() {
  const { lang } = useGlobalContext()
  return (
    <footer className="flex flex-col items-center justify-center space-y-4 px-4">
      <p className="text-sm md:text-base">
        {lang === 'zh' ? '已验证智能合约地址:' : 'VERIFIED SMART CONTRACT ADDRESS:'}
        <a
          className="text-brand underline break-all"
          target="_blank"
          href={`https://etherscan.io/token/${REACT_APP_CONTRACT_ADDRESS}`}
          rel="noreferrer"
        >
          {REACT_APP_CONTRACT_ADDRESS}
        </a>
      </p>
      <img src={footer} alt="" className="w-[300px] max-w-[50vw]" />
    </footer>
  )
}
