import zh from '../assets/text/team.zh.png'
import en from '../assets/text/team.en.png'
import { useGlobalContext } from './context'
import { Name } from './name'

export default function Team() {
  const { lang } = useGlobalContext()
  return (
    <>
      <h2 className="flex flex-col items-center mt-16 md:mt-20 mb-4 md:mb-8">
        <img src={lang === 'zh' ? zh : en} alt="" className="h-8 sm:h-10 md:h-16" aria-hidden />
        <span className="sr-only">{lang === 'zh' ? '团队' : 'Team'}</span>
      </h2>

      <section className="flex flex-col items-center space-y-2 mb-12 px-4 font-semibold">
        {lang === 'zh' ? (
          <p>
            <Name title="New Boy Xiao Ming" /> 是由
            <Name title="Y2 Lab" />
            创建的，他们制作出小明，希望吸引趣味相同的人，尝试与社区的成员一起构建和探索更多的东西。
          </p>
        ) : (
          <p>
            <Name title="New Boy Xiao Ming" /> is made by <Name title="Y2 Lab" />, they made the style of{' '}
            XiaoMing. And We are looking for you to join us and try to build more.
          </p>
        )}
      </section>
    </>
  )
}
