const { REACT_APP_SOCIAL_DISCORD, REACT_APP_SOCIAL_OPEN_SEA, REACT_APP_SOCIAL_TWITTER, REACT_APP_SOCIAL_WEIBO } =
  process.env

export const SOCIALS = {
  Discord: { icon: Discord, url: REACT_APP_SOCIAL_DISCORD },
  Twitter: { icon: Twitter, url: REACT_APP_SOCIAL_TWITTER },
  Weibo: { icon: Weibo, url: REACT_APP_SOCIAL_WEIBO },
  OpenSea: { icon: OpenSea, url: REACT_APP_SOCIAL_OPEN_SEA },
}

export function Discord({ className }) {
  return (
    <svg className={className} fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M50.414 11.704a48.662 48.662 0 0 0-11.89-3.7c-.08 0-.16 0-.19.09-.51.92-1.09 2.1-1.48 3.04-4.52-.67-8.96-.67-13.35 0-.41-.97-.98-2.13-1.56-3.04-.05-.06-.11-.11-.19-.09-3.58.72-8.16 2.03-11.84 3.69-.03 0-.06.03-.08.06-7.62 11.38-9.65 22.28-8.56 33.19 0 .05.03.11.08.16 4.99 3.68 9.81 5.92 14.58 7.37.08 0 .16 0 .2-.06 1.12-1.53 2.12-3.15 2.99-4.86.05-.09 0-.22-.11-.25-1.56-.62-3.12-1.34-4.56-2.18-.11-.06-.12-.23 0-.31.31-.23.62-.47.9-.7.05-.05.12-.05.19-.03 9.57 4.36 19.94 4.36 29.29 0 .06-.03.14-.02.19.02.3.25.59.48.9.72.11.08.09.25 0 .31-1.45.86-2.96 1.56-4.56 2.18-.11.05-.16.16-.09.26.87 1.71 1.88 3.32 2.96 4.83.05.06.12.09.2.06 4.77-1.48 9.61-3.71 14.61-7.37.05-.03.08-.08.08-.16 1.22-12.6-2.04-23.52-8.63-33.18 0-.03-.05-.06-.08-.06v.02-.01Zm-29.91 26.63c-2.96 0-5.3-2.65-5.3-5.92s2.34-5.92 5.3-5.92 5.3 2.65 5.3 5.92-2.34 5.92-5.3 5.92Zm19.47 0c-2.96 0-5.3-2.65-5.3-5.92s2.34-5.92 5.3-5.92 5.3 2.65 5.3 5.92-2.34 5.92-5.3 5.92Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function Weibo({ className }) {
  return (
    <svg className={className} fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.175 5.01c-1.04-.1-2.01.53-2.15 1.53-.14.98.56 1.95 1.53 2.15 3.62.8 10.46 1.85 12.27 12.04.16 1.03 1.01 2.13 2.29 1.85.94-.21 1.78-.73 1.36-3.12-1.8-10.04-9.77-14.06-15.3-14.45ZM45.825 20.95c-.37-1.9-2.26-3.95-4.44-4.29-.67-.11-1.27.29-1.47.93-.21.7.18 1.5.89 1.65 1.51.32 2.25 1.36 2.47 2.47 0 0 .19.75.92.96.71.2 1.99-.32 1.63-1.72Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M51.194 20.7c-.61-4.72-4.14-8.6-8.89-9.42-1.59-.27-2.32.86-2.32 1.77s.65 1.56 1.84 1.9c3.18.88 5.22 2.28 5.78 5.95.16 1.07 1.14 1.64 1.85 1.64.96 0 1.73-.78 1.73-1.73v-.1l.01-.01ZM41.984 39.91c-.64 6.61-10.07 11.91-20.29 11.65-9.48-.23-15.79-5.22-14.74-11.01 1.02-5.62 8.77-10.48 17.88-11.02 9.79-.58 17.79 3.98 17.16 10.39l-.01-.01Zm-1.49-10.6c-.38.01-.54.05-.59-.08-.05-.11 0-.23.21-.74.57-1.43 1.47-4.38-.38-6.02-1.22-1.08-6.41-1.91-11.87 1.88-.27.19-.71.59-.94.52-.25-.08-.3-.35-.33-.85-.18-2.7 2.09-10.2-3.76-9.57-6.22 1.05-14.61 10.88-17.01 15.64-8.51 16.87 3.8 23.75 15.84 24.11 13.07.39 26.3-7.03 28.04-16.96 1.16-6.62-6.64-7.97-9.21-7.92v-.01Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.125 40.83c-.06.5-.49.91-.96.92-.46 0-.78-.38-.72-.87.06-.49.47-.89.93-.9.46-.03.8.36.75.85Zm-1.58 2.36c.16-1.54-.87-2.72-2.32-2.66-1.42.07-2.71 1.31-2.89 2.8-.19 1.5.81 2.71 2.24 2.69 1.45-.02 2.79-1.3 2.97-2.83Zm.56-9.23c-4.33.23-8.22 3.66-8.83 7.74-.63 4.16 2.36 7.61 6.81 7.62 4.62.02 8.89-3.62 9.38-8.05.48-4.33-2.87-7.57-7.35-7.32l-.01.01Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function Twitter({ className }) {
  return (
    <svg className={className} fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M58.67 12.54c-2.12.94-4.4 1.58-6.79 1.86 2.44-1.46 4.32-3.78 5.2-6.54a23.548 23.548 0 0 1-7.51 2.87C47.41 8.43 44.34 7 40.93 7 34.4 7 29.1 12.29 29.1 18.83c0 .93.1 1.83.3 2.7-9.83-.49-18.55-5.2-24.38-12.36-1.02 1.75-1.6 3.78-1.6 5.95 0 4.1 2.09 7.72 5.26 9.85-1.94-.06-3.76-.59-5.36-1.48v.15c0 5.73 4.08 10.51 9.49 11.6-.99.27-2.04.41-3.12.41-.76 0-1.5-.07-2.23-.21 1.51 4.7 5.87 8.12 11.05 8.21A23.76 23.76 0 0 1 1 48.55a33.458 33.458 0 0 0 18.13 5.32c21.76 0 33.66-18.03 33.66-33.66 0-.51-.01-1.02-.04-1.53a24.08 24.08 0 0 0 5.91-6.13l.01-.01Z"
        fill="currentColor"
      />
    </svg>
  )
}

export function OpenSea({ className }) {
  return (
    <svg className={className} fill="none" viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
      <path
        d="m6.28 33.08.18-.27 11.51-17.98c.18-.27.53-.27.71.09 1.95 4.34 3.54 9.66 2.83 13.02-.35 1.42-1.24 3.28-2.3 4.96-.09.27-.27.53-.44.71-.09.09-.18.18-.35.18H6.54c-.27-.09-.44-.44-.27-.71h.01Z"
        fill="currentColor"
      />
      <path
        d="M57.56 36.45v2.83c0 .18-.09.27-.27.35-.89.35-3.99 1.77-5.23 3.54-3.28 4.52-5.76 10.98-11.25 10.98H17.79C9.64 54.15 3 47.51 3 39.27V39c0-.18.18-.35.35-.35h12.94c.27 0 .44.27.44.44-.09.8.09 1.68.44 2.48.8 1.59 2.39 2.48 4.07 2.48h6.38v-4.96h-6.29c-.35 0-.53-.35-.35-.62.09-.09.18-.18.27-.35.62-.89 1.42-2.13 2.3-3.63.62-.97 1.15-2.13 1.59-3.19.09-.18.18-.35.27-.62.09-.35.27-.71.35-.97l.27-.8c.18-.89.27-1.86.27-2.92 0-.35 0-.8-.09-1.24 0-.44-.09-.89-.09-1.33 0-.35-.09-.8-.18-1.15-.09-.62-.18-1.15-.35-1.77l-.09-.18c-.09-.35-.18-.8-.35-1.15-.35-1.24-.8-2.48-1.24-3.54l-.53-1.33c-.27-.62-.53-1.24-.8-1.77-.09-.27-.27-.44-.35-.71-.09-.27-.27-.53-.35-.8l-.27-.53-.8-1.42c-.09-.18.09-.44.27-.35l4.87 1.33.62.18.71.18.27.09V7.57C27.55 6.15 28.7 5 30.03 5c.71 0 1.33.27 1.77.71.44.44.71 1.06.71 1.77v4.25l.53.18s.09 0 .09.09c.09.09.27.27.53.44.18.18.35.35.62.53.44.35 1.06.89 1.68 1.42.18.18.35.27.44.44.8.71 1.68 1.59 2.57 2.57.27.27.44.53.71.8.27.27.44.62.71.89l.89 1.15c.09.18.27.35.35.62.35.53.62 1.06.97 1.59.09.27.27.53.35.8.35.71.62 1.42.71 2.21.09.18.09.35.09.44.09.18.09.44.09.71.09.8 0 1.51-.09 2.3-.09.35-.18.62-.27.97-.09.27-.18.62-.35.97-.27.62-.62 1.24-.97 1.86-.09.18-.27.44-.44.71-.17.27-.35.44-.44.71-.18.27-.44.53-.62.8s-.35.53-.62.8c-.27.35-.62.71-.89 1.06-.18.18-.35.44-.62.62-.18.18-.35.44-.62.62-.27.27-.53.53-.8.71l-.53.44c-.09.09-.18.09-.27.09h-3.9v4.96h4.87c1.06 0 2.13-.35 2.92-1.06.27-.27 1.51-1.33 3.01-2.92.06-.06.12-.09.18-.09L57.12 36c.27-.09.44.18.44.44v.01Z"
        fill="currentColor"
      />
    </svg>
  )
}
