import zh from '../assets/text/who.zh.png'
import en from '../assets/text/who.en.png'
import { useGlobalContext } from "./context"
import Avatars from './Avatars'

export default function WhoIsXiaoMing() {
  const { lang } = useGlobalContext()
  return (
    <>
      <h2 className="mb-6 mt-16 md:mt-20 md:pl-4">
        <img src={lang === 'zh' ? zh : en} alt="" className="h-10 md:h-16" aria-hidden />
        <span className="sr-only">{lang === 'zh' ? '谁是小明？' : 'Who is XiaoMing'}</span>
      </h2>

      <section className="space-y-2 mb-4 md:mb-8 md:pl-4 font-semibold">
        {lang === 'zh' ? (
          <>
            <p>小明是你，小明是我，小明可以是任何人。</p>
            <p>每一个小明都是独一无二的，但有些比其他更罕见。</p>
          </>
        ) : (
          <>
            <p>XiaoMing could be you and me, he could be everyone.</p>
            <p>Each XiaoMing is unique, but some are rarer than others.</p>
          </>
        )}
      </section>

      <Avatars />
    </>
  )
}