import a1 from '../assets/avatar/1.png'
import a2 from '../assets/avatar/2.png'
import a3 from '../assets/avatar/3.png'
import a4 from '../assets/avatar/4.png'
import a5 from '../assets/avatar/5.png'
import a6 from '../assets/avatar/6.png'
import a7 from '../assets/avatar/7.png'
import a8 from '../assets/avatar/8.png'
import a9 from '../assets/avatar/9.png'
import a10 from '../assets/avatar/10.png'
import a11 from '../assets/avatar/11.png'
import a12 from '../assets/avatar/12.png'
import a13 from '../assets/avatar/13.png'
import a14 from '../assets/avatar/14.png'
import a15 from '../assets/avatar/15.png'

const avatars = [a1, a2, a3, a4, a5, a6, a7, a8, a9, a10, a11, a12, a13, a14, a15]

export default function Avatars() {
  return (
    <ul className="flex flex-wrap -mx-2 md:-mx-4">
      {avatars.map(avatar => (
        <li key={avatar} className="w-1/5 p-2 md:p-4">
          <div className="relative">
            <i className="block w-full pb-[100%]" aria-hidden />
            <img
              src={avatar}
              alt=""
              loading="lazy"
              className="absolute inset-0 rounded ring-2 ring-white"
            />
          </div>
        </li>
      ))}
    </ul>
  )
}
