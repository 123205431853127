import { useGlobalContext } from "./context"
import logo from '../assets/logo.png'
import outline from '../assets/outline.png'

const { REACT_APP_APP_TITLE } = process.env

export default function Introduction() {
  const { lang } = useGlobalContext()
  return (
    <>
      <h2 className="mb-6 mt-16 md:mt-20 md:pl-4">
        <img src={logo} alt="" className="h-10 md:h-16" aria-hidden />
        <span className="sr-only">{REACT_APP_APP_TITLE}</span>
      </h2>

      <section className="space-y-2 mb-4 md:mb-8 md:pl-4 font-semibold">
        {lang === 'zh' ? (
          <>
            <p>NBXM是10,000个小明NFT的集合——生活在以太坊区块链上的独特数字收藏品。</p>
            <p>有200多个特征组成，包括表情、头饰、服装等。</p>
          </>
        ) : (
          <>
            <p>NBXM is a collection of 10,000 XiaoMing NFTs-unique digital collectibles living on the Ethereum blockchain.</p>
            <p>There are over 200 possible traits, including expression, hairstyle, clothing, and more.</p>
          </>
        )}
      </section>

      <div className="md:px-8 mx-auto">
        <img src={outline} alt="" aria-hidden className="w-full" />
      </div>
    </>
  )
}