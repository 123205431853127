import { createContext, useContext } from 'react'

export const LANGUAGES = {
  en: 'English',
  zh: '简体中文',
}

const GlobalContext = createContext(
  {}
)

export const GlobalContextProvider = GlobalContext.Provider

export function useGlobalContext() {
  return useContext(GlobalContext)
}
