import React, { useState } from "react";
import { GlobalContextProvider } from './components/context'
import { createElement } from 'react'
import Header from './components/Header'
import Club from './components/Club'
import analects from './assets/text-analects.png'
import inlineGuys from './assets/guy-inline.png'
import Footer from './components/Footer'
import { SOCIALS } from './components/social'
import MintButton from './components/MintButton'
import Introduction from './components/Introduction'
import WhoIsXiaoMing from './components/WhoIsXiaoMing'
import Welcome from './components/Welcome'
import Team from './components/Team'
import Buy from './components/Buy'

function getLang() {
  return (
    localStorage.getItem('lang') ||
    (navigator.language.toLowerCase().indexOf('zh') === 0 ? 'zh' : 'en')
  )
}

function App() {
  const [lang, setLang] = useState(getLang())

  return (
    <GlobalContextProvider
      value={{ lang, setLang }}
    >
      <div className="max-w-[1350px] mx-auto px-4">
        <Header />

        <Club />

        <div className="mb-6 sm:mb-8 md:mb-16">
          <img src={analects} alt="" className="mx-auto h-4 sm:h-9" />
          <span className="sr-only">有朋自远方来 不亦乐乎</span>
        </div>

        <MintButton />

        <div className="my-16 md:px-8 md:my-24">
          <img src={inlineGuys} alt="" className="w-full" />
        </div>

        <Buy />

        <Introduction />

        <WhoIsXiaoMing />

        <Welcome />

        <Team />

        <div className="flex justify-center my-8 md:my-12 lg:my-16">
          <nav>
            <ul className="flex items-center space-x-8 md:space-x-14">
              {Object.entries(SOCIALS).map(([name, { icon, url }]) => (
                <li key={name}>
                  <a href={url} target="_blank" rel="noreferrer">
                    {createElement(icon, { className: 'h-8 md:h-10 text-white' })}
                    <span className="sr-only">{name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        </div>

        <Footer />
      </div>
    </GlobalContextProvider>
  );
}

export default App;
