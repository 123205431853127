import zh from '../assets/text/welcome.zh.png'
import en from '../assets/text/welcome.en.png'
import { useGlobalContext } from "./context"

export default function Welcome() {
  const { lang } = useGlobalContext()
  return (
    <>
      <h2 className="flex flex-col items-center mt-16 md:mt-20 mb-4 md:mb-8">
        <img src={lang === 'zh' ? zh : en} alt="" className="h-8 sm:h-10 md:h-16" aria-hidden />
        <span className="sr-only">
          {lang === 'zh' ? '欢迎你加入小明兄弟会' : 'Welcome to the club'}
        </span>
      </h2>

      <section className="text-center font-semibold">
        {
          lang === 'zh' ? (
            <>
              <p>当您购买小明时，您不仅仅是购买了一个头像或一件可证明稀有的艺术品。</p>
              <p>您正在获得一个兄弟会的会员资格，你的小明可以作为你的数字身份，为你打开数字大门。该兄弟会会员的福利和产品将随着时间而逐步推出。</p>
              <p className="mt-8">NBXM渴望建立一个休闲、有趣的社区。</p>
              <p>忘掉烦恼、忘掉悲伤、让我们在网络世界快乐冲浪!</p>
              <p className="mt-8">让我们聚在一起在建造一个属于小明的元宇宙吧。</p>
            </>
          ) : (
            <>
              <p>When you buy a XiaoMing, you're not simply buying an avatar or a provably-rare piece of art.</p>
              <p>You are gaining membership access to a club whose benefits and offerings will increase over time. </p>
              <p>Your XiaoMing can serve as your digital identity, and open digital doors for you.</p>
              <p className="mt-8">NBXM is longing to build a fun and relaxing community.</p>
              <p>Let's realize our great dream—surfing in the Metaverse.</p>
              <p className="mt-8">Let's gather and build a decentralized place that belongs to Xiao Ming and you.</p>
            </>
          )
        }
      </section>
    </>
  )
}