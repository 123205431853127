import zh from '../assets/text/buy.zh.png'
import en from '../assets/text/buy.en.png'
import { useGlobalContext } from "./context"
import Card from './Card'

export default function Buy() {
  const { lang } = useGlobalContext()
  return (
    <>
      <h2 className="mt-16 md:mt-20 md:pl-4" id="mint-card">
        <img src={lang === 'zh' ? zh : en} alt="" className="h-10 md:h-16" aria-hidden />
        <span className="sr-only">{lang === 'zh' ? '购买一个小明' : 'Buy a XiaoMing'}</span>
      </h2>

      <Card />
    </>
  )
}